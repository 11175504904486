.glide__arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  padding: 9px 12px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1;
}
.glide__arrow:focus {
  outline: none;
}
.glide__arrow:hover {
  border-color: white;
}
.glide__arrow--left {
  right: 2em;
}
.glide__arrow--right {
  left: 2em;
}
.glide__arrow--disabled {
  opacity: 0.33;
}
.glide__bullets {
  position: absolute;
  z-index: 2;
  bottom: 2em;
  right: 50%;
  display: inline-flex;
  list-style: none;
  transform: translateX(50%);
}
.glide__bullet {
  background-color: rgba(255, 255, 255, 0.5);
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  line-height: 0;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  margin: 0 0.25em;
}
.glide__bullet:focus {
  outline: none;
}
.glide__bullet:hover, .glide__bullet:focus {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.5);
}
.glide__bullet--active {
  background-color: white;
}
.glide--swipeable {
  cursor: grab;
  cursor: -webkit-grab;
}
.glide--dragging {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

/*# sourceMappingURL=glide.theme.css.map */